import React, { useReducer, useContext, createContext, useEffect } from "react";

const CartStateContext = createContext();
const CartDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      return [
        ...state,
        {
          productId: action.productId,
          name: action.name,
          price: action.price,
          img: action.img,
          qty: action.qty,
          cost: action.cost,
        },
      ];
    case "REMOVE":
      let newArr = [...state];
      newArr.splice(action.index, 1);
      return newArr;
    case "DROP":
      let empArray = [];
      return empArray;
    case "UPDATE":
      return state.map((item) => {
        if (item.productId === action.productId) {
          return { ...item, qty: parseInt(action.qty), price: action.price };
        }
        return item;
      });
    case "CLEAR_CART":
      return [];

    case "INITIALIZE_CART":
      return action.cartData;

    default:
      console.log("Error in Reducer");
  }
};

export const CardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, [], () => {
    const storedCartData = sessionStorage.getItem("cartData");
    return storedCartData ? JSON.parse(storedCartData) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("cartData", JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    dispatch({
      type: "INITIALIZE_CART",
      cartData: JSON.parse(sessionStorage.getItem("cartData")) || [],
    });
  }, []);

  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

export const useCart = () => useContext(CartStateContext);
export const useDispatchCart = () => useContext(CartDispatchContext);
