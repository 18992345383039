import { React, createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { realtimeDb, ref, onValue, off, update } from "../db/firebaseConfig";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    sessionStorage.getItem("kiosk_token") || null
  );
  const [user, setUser] = useState(() => {
    const savedUser = sessionStorage.getItem("kiosk_user");
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loginUser = async (loginKey, pin) => {
    setLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/login-kiosk`,
        {
          loginKey,
          pin,
        }
      );
      const token = result.data.token;
      const user = result.data.data;
      setToken(token);
      setUser(user);
      return result;
    } finally {
      setLoading(false);
    }
  };

  const logoutUser = async () => {
    if (user && user.id) {
      const userRef = ref(realtimeDb, `users/${user.id}`);
      try {
        await update(userRef, {
          pinChanged: false,
          tokenExpired: false,
          logoutAllKioskDevices: false,
        });
      } catch (error) {
        console.error("Failed to reset pinChanged flag:", error);
      }
    }
    setToken(null);
    setUser(null);
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("kiosk_token", token);
      sessionStorage.setItem("kiosk_user", JSON.stringify(user));

      const userRef = ref(realtimeDb, `users/${user.id}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (
            data.tokenExpired ||
            data.pinChanged ||
            data.deleted ||
            data.disableKiosk === true ||
            data.logoutAllKioskDevices
          ) {
            logoutUser();
          }
        }
      });

      return () => {
        off(userRef);
      };
    } else {
      sessionStorage.clear();
    }
  }, [token, user]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const expiryTime = decodedToken.exp * 1000;
      const remainingTime = expiryTime - Date.now();

      if (remainingTime > 0) {
        const timer = setTimeout(() => {
          logoutUser();
        }, remainingTime);

        return () => clearTimeout(timer);
      } else {
        logoutUser();
      }
    }
  }, [token]);

  const authValue = {
    token,
    user,
    loginUser,
    logoutUser,
    loading,
    setUser,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
